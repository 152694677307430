.side-navigation-panel ::after {
}

.side-navigation-panel {
  background-color: rgba(216, 216, 216, 0);
  width: 100%;
}

.side-navigation-panel ::before {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  border-color: #e2e8f0;
}

.side-navigation-panel ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  border-left-width: 2px;
  background-color: rgba(216, 216, 216, 0);
  cursor: pointer;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-wrap {
  display: flex;
  align-items: center;
  margin: 0 0 0 1.4375rem;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-wrap
  .side-navigation-panel-select-option-text {
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: 600;
  color: #3f3b3b;
  font-size: 0.875rem;
  letter-spacing: 0;
  font-family: Open Sans;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-icon,
svg {
  display: block;
  vertical-align: middle;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-icon-chevron-up {
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option
  .side-navigation-panel-select-option-icon-chevron-down {
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(206, 239, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  border-right: 4.54px solid #0172d4;
}

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option {
  display: block;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  border-left-width: 2px;
  cursor: pointer;
  width: 100%;
}
.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-inner
  .side-navigation-panel-select-inner-wrap
  .side-navigation-panel-select-inner-option-selected {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
